import React from "react";

const Section1 = () => {
  return (
    <div className="bg-[#1A1A1A] px-4 py-8 md:h-[499.56px] flex items-center justify-center">
      <p className="text-white text-center text-2xl md:text-[38px] font-semibold leading-7 md:leading-[44.65px]">
        <span className="text-[#F04D24]">Sidhgiri Holdings</span> is one of the
        <br />
        leading and branded Coal
        <br />
        Supplier and Importer in
        <br />
        Indian Market.
      </p>
    </div>
  );
};

export default Section1;
