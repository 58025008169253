import React from "react";

const Navbar = () => {
  return (
    <nav className="bg-[#1A1A1A] h-9 px-[20px] md:flex md:justify-between md:items-center py-2.5 md:py-0">
      <div className="flex items-center justify-center space-x-4 text-[10px] md:text-xs">
        <div className="text-white">
          <span className="text-white">Call Us:</span>{" "}
          <span className="text-[#F04D24]">+91-9999999999</span> |{" "}
          <span className="text-white">Email Us:</span>{" "}
          <span className="text-[#F04D24]">sales@sidhgiriholdings.com</span>
        </div>
      </div>
      <div className="hidden md:flex items-center space-x-2 text-xs">
        <a href="#" className="text-[#F04D24] hover:text-gray-300">
          Terms and Conditions
        </a>
        <span className="text-white">|</span>
        <a href="#" className="text-[#F04D24] hover:text-gray-300">
          Privacy Policy
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
