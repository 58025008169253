import React, { useState } from "react";
import logo from "../images/logo-2.svg";
import rightArrowIcon from "../images/right-arrow.svg";
import heroVideo from "../images/bg.mp4";

const Hero = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="relative h-screen">
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
        src={heroVideo}
      >
        Your browser does not support the video tag.
      </video>

      {/* Navbar */}
      <nav className="z-10 relative flex justify-between items-center px-4 md:px-16">
        <div className="flex items-center space-x-5">
          <img src={logo} alt="Logo" className="w-32 h-10 md:w-48 md:h-14" />
        </div>
        <div className="md:hidden mt-2">
          <button
            onClick={toggleMenu}
            className="text-white hover:text-[#F04D24] focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>
        <div className="hidden md:flex items-center space-x-8 text-sm">
          <a href="#about-us" className="text-white hover:text-[#F04D24]">
            About Us
          </a>
          <a href="#services" className="text-white hover:text-[#F04D24]">
            Services
          </a>
          <a href="#contact-us" className="text-white hover:text-[#F04D24]">
            Contact Us
          </a>
        </div>
      </nav>

      {/* Thin Line */}
      <hr className="border-t border-slate-100 my-1 opacity-20 z-10" />

      {/* Additional Content */}
      <div className="z-10 relative flex flex-col items-center md:items-start md:pl-72 pt-32 md:pt-80 text-white">
        <p className="text-4xl md:text-7xl font-semibold mb-2 text-center md:text-left leading-tight">
          <span>India's Leading</span> <br /> <span>Coal Supplier</span>
        </p>
        <p className="text-base mb-4 md:mb-6 font-extralight text-center md:text-left">
          Sidhgiri Holdings: Leading USA Coal Importer
        </p>
        <button className="flex items-center bg-[#F04D24] text-white mt-4 md:mt-8 py-3 px-6 rounded-full text-sm font-light">
          Buy Coal
          <img
            src={rightArrowIcon}
            alt="Right Arrow"
            className="ml-2 h-5 w-5"
          />
        </button>
      </div>

      {/* Responsive Hamburger Menu */}
      {menuOpen && (
        <div className="md:hidden absolute top-0 left-0 w-full h-screen bg-gray-900 bg-opacity-90">
          <div className="flex flex-col items-center justify-center h-full mt-24">
            <a
              href="#about-us"
              className="text-white text-xl my-4 hover:text-[#F04D24]"
            >
              About Us
            </a>
            <a
              href="#services"
              className="text-white text-xl my-4 hover:text-[#F04D24]"
            >
              Services
            </a>
            <a
              href="#contact-us"
              className="text-white text-xl my-4 hover:text-[#F04D24]"
            >
              Contact Us
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Hero;
