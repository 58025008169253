import React from "react";
import logo from "../images/logo-orange.svg";
import youtube from "../images/yt-gray.svg";
import twitter from "../images/twitter-gray.svg";
import linkedin from "../images/linkedin-gray.svg";

const Footer = () => {
  return (
    <footer className="bg-[#1A1A1A] text-white py-6 md:pt-32 md:pb-10">
      <div className="container mx-auto md:flex justify-between items-center">
        {/* Left Part - Logo */}
        <div className="w-[300px] mb-auto md:ml-48 hidden md:block">
          <img src={logo} alt="Logo" className="w-30 h-30" />
        </div>

        {/* Right Part - Columns */}
        <div className="flex space-x-10 px-8 pb-16 md:pb-48 md:flex md:space-x-36 w-full">
          {/* Our Company Column */}
          <div className="space-y-4 w-[33.33%] md:space-y-0 md:w-[25%]">
            <h4 className="text-[10px] md:text-[13px] text-white/60 font-normal mb-4">
              Our Company
            </h4>
            <div className="space-y-2">
              <span className="hover:text-gray-400 text-[12px] md:text-[19px] mb-2 block cursor-pointer font-light">
                About Us
              </span>
              <span className="hover:text-gray-400 text-[12px] md:text-[19px] mb-2 block cursor-pointer font-light">
                Products
              </span>
              <span className="hover:text-gray-400 text-[12px] md:text-[19px] mb-2 block cursor-pointer font-light">
                Team
              </span>
              <span className="hover:text-gray-400 text-[12px] md:text-[19px] block cursor-pointer font-light">
                Contact Us
              </span>
            </div>
          </div>

          {/* For Investors Column */}
          <div className="space-y-4 w-[33.33%] md:space-y-0 md:w-[25%]">
            <h4 className="text-[10px] md:text-[13px] text-white/60 font-normal mb-4">
              For Investors
            </h4>
            <div className="space-y-2">
              <span className="hover:text-gray-400 text-[12px] md:text-[19px] block cursor-pointer font-light">
                Investors Relations
              </span>
              <span className="hover:text-gray-400 text-[12px] md:text-[19px] block cursor-pointer font-light">
                CSR Policy
              </span>
              <span className="hover:text-gray-400 text-[12px] md:text-[19px] block cursor-pointer font-light">
                Mainboard Listing
              </span>
            </div>
          </div>

          {/* Legal Column */}
          <div className="space-y-4 w-[33.33%] md:space-y-0 md:w-[25%]">
            <h4 className="text-[10px] md:text-[13px] text-white/60 font-normal mb-4">
              Legal
            </h4>
            <div className="space-y-2">
              <span className="hover:text-gray-400 text-[12px] md:text-[19px] block cursor-pointer font-light">
                Terms and Conditions
              </span>
              <span className="hover:text-gray-400 text-[12px] md:text-[19px] block cursor-pointer font-light">
                Privacy Policy
              </span>
              <span className="hover:text-gray-400 text-[12px] md:text-[19px] block cursor-pointer font-light">
                Updates
              </span>
            </div>
          </div>

          {/* Social Media Column (Hidden on Mobile) */}
          <div className="hidden md:block space-y-2 md:w-[25%]">
            <h4 className="text-[13px] text-white/60 font-normal mb-4">
              Social Media
            </h4>
            <div className="space-y-2">
              <span className="hover:text-gray-400 block cursor-pointer">
                LinkedIn
              </span>
              <span className="hover:text-gray-400 text-[19px] block cursor-pointer font-light">
                Twitter
              </span>
              <span className="hover:text-gray-400 text-[19px] block cursor-pointer font-light">
                Facebook
              </span>
              <span className="hover:text-gray-400 text-[19px] block cursor-pointer font-light">
                Android App
              </span>
              <span className="hover:text-gray-400 text-[19px] block cursor-pointer font-light">
                iPhone App
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Thin Line */}
      <hr className="border-t border-slate-100 my-1 opacity-20" />

      {/* Bottom Part */}
      <div className="flex flex-col md:flex-row items-center justify-between px-4 md:px-24 mt-4 md:mt-6">
        {/* Left side - Texts */}
        <div className="flex flex-col items-center justify-center md:flex-row md:space-x-4 w-full md:w-[765px] md:ml-0 md:ml-48">
          <span className="text-[13px] text-white/60 font-light">
            © 2023 Sidhgiri Holdings. All rights reserved.
          </span>
          <span className="text-[13px] text-white/60 font-light">
            Privacy Policy
          </span>
          <span className="text-[13px] text-white/60 font-light">
            Terms of Service
          </span>
        </div>

        {/* Right side - Logos */}
        <div className="flex space-x-4 mt-4 md:mt-0">
          <img src={youtube} alt="youtube" className="w-6 h-6 cursor-pointer" />
          <img src={twitter} alt="twitter" className="w-6 h-6 cursor-pointer" />
          <img src={linkedin} alt="linkedin" className="w-6 h-6 cursor-pointer" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
