import React from "react";
import tiltedArrow from "../images/tilted-arrow.svg";

const Section3 = () => {
  return (
    <section
      id="services"
      className="bg-[#F04D24] py-8 md:py-16 text-white text-center"
    >
      {/* Header */}
      <div className="text-4xl md:text-[106px] font-normal mb-4 md:mb-8 mt-6 md:mt-12 md:mr-36 leading-none">
        <span className="mr-16">How it</span>
        <br />
        <span className="ml-8 md:ml-48">works</span>
      </div>

      {/* Flex Items */}
      <div className="flex flex-col md:flex-row justify-center space-y-8 md:space-y-0 space-x-0 md:space-x-6 text-left py-4 md:py-12 px-4 md:px-24">
        <div className="w-full md:w-1/4">
          <p className="text-2xl md:text-3xl">1</p>
          <div className="flex items-center">
            <p className="text-2xl md:text-3xl font-normal mb-2 text-black">
              Open Market
            </p>
            <img
              src={tiltedArrow}
              alt="Tilted Arrow"
              className="w-8 h-8 md:w-12 md:h-12 ml-2 md:ml-4"
            />
          </div>
          <ul className="text-sm md:text-base list-disc pl-4 md:pl-6 font-extralight">
            <li>
              Delivery within the stipulated time, with assured GCV and moisture
              content
            </li>
          </ul>
        </div>
        <div className="w-full md:w-1/4">
          <p className="text-2xl md:text-3xl">2</p>
          <div className="flex items-center">
            <p className="text-2xl md:text-3xl font-normal mb-2 text-black">
              Linkage Control
            </p>
            <img
              src={tiltedArrow}
              alt="Tilted Arrow"
              className="w-8 h-8 md:w-12 md:h-12 ml-2 md:ml-4"
            />
          </div>
          <ul className="text-sm md:text-base list-disc pl-4 md:pl-6 font-extralight">
            <li>
              Handling, transporting, licensing and ensuring seamless quality
              and delivery.
            </li>
          </ul>
        </div>
        <div className="w-full md:w-1/4">
          <p className="text-2xl md:text-3xl">3</p>
          <div className="flex items-center">
            <p className="text-2xl md:text-3xl font-normal mb-2 text-black">
              Spot e-Auction
            </p>
            <img
              src={tiltedArrow}
              alt="Tilted Arrow"
              className="w-8 h-8 md:w-12 md:h-12 ml-2 md:ml-4"
            />
          </div>
          <ul className="text-sm md:text-base list-disc pl-4 md:pl-6 font-extralight">
            <li>
              Our experts of business makes sure we choose best coal mines
              according to your requirements.
            </li>
          </ul>
        </div>
        <div className="w-full md:w-1/4">
          <p className="text-2xl md:text-3xl">4</p>
          <div className="flex items-center">
            <p className="text-2xl md:text-3xl font-normal mb-2 text-black">
              Imported Coal
            </p>
            <img
              src={tiltedArrow}
              alt="Tilted Arrow"
              className="w-8 h-8 md:w-12 md:h-12 ml-2 md:ml-4"
            />
          </div>
          <ul className="text-sm md:text-base list-disc pl-4 md:pl-6 font-extralight">
            <li>Offering diverse imported coal varieties</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Section3;
