import React from "react";
import coalImage1 from "../images/coal-1.png";
import coalImage2 from "../images/coal-2.png";
import coalImage3 from "../images/coal-3.png";

const Section5 = () => {
  return (
    <div className="bg-gray-50 py-8 md:py-32 text-black text-center">
      {/* Flex Items */}
      <div className="flex flex-col items-center md:flex-row justify-center space-y-6 md:space-y-0 md:space-x-10 text-left px-4 md:px-32">
        <div className="w-full md:w-[400px] md:mr-8">
          <p className="text-2xl md:text-4xl font-normal mb-4 md:mb-24 leading-10">
            Business Insider
          </p>
        </div>
        <div className="w-full md:w-[770px]">
          <p className="text-base md:text-lg mb-4 md:mb-2 font-light leading-[30px]">
            The company specializes in, but not limited to, supplying High GCV
            USA Coal (American Coal or Napp Coal), Low GCV USA Coal (ILB Coal),
            Russian Coal, Australian Coal & Indonesian Coal. Majority of
            Imports are concentrated on, but not limited to, Kandla Port and
            Tuna Port in Gujarat near Gandhidham. The company Imports Ships /
            Vessel of USA Coal on Kandla or Tuna Port from time-to-time and
            supplies it on both bulk basis and truck by truck basis.
          </p>
        </div>
      </div>

      {/* Images and Alerts */}
      <div className="flex flex-col space-y-12 md:space-y-0 md:space-x-6 md:flex-row md:justify-center mt-8 md:mt-32 px-4 md:px-32">
        <div className="w-full md:w-[380px] flex flex-col items-center text-left">
          <img src={coalImage1} alt="Coal 1" className="w-full md:w-[380px]" />
          <p className="text-gray-500 mt-6 font-semibold bg-white border border-1 py-1 px-3 text-xs rounded-3xl">
            Alerts
          </p>
          <p className="text-black text-base mt-4">
            India’s coal imports increased to 162 MT in FY23, and inbound
            shipments of coking coal increased to 54 MT.
          </p>
          <p className="text-[#F04D24] mt-4">
            Team <strong>Sidhgiri</strong> - July 19, 2023
          </p>
        </div>
        <div className="w-full md:w-[380px] flex flex-col items-center text-left">
          <img src={coalImage2} alt="Coal 2" className="w-full md:w-[380px]" />
          <p className="text-gray-500 mt-6 font-semibold bg-white border border-1 py-1 px-3 text-xs rounded-3xl">
            Alerts
          </p>
          <p className="text-black text-base mt-4">
            Maximizing Results: The Ultimate Guide on How to Integrate Organic
            and Paid Marketing Strategies
          </p>
          <p className="text-[#F04D24] mt-10">
            Team <strong>Sidhgiri</strong> - July 22, 2023
          </p>
        </div>
        <div className="w-full md:w-[380px] flex flex-col items-center text-left">
          <img src={coalImage3} alt="Coal 3" className="w-full md:w-[380px]" />
          <p className="text-gray-500 mt-6 font-semibold bg-white border border-1 py-1 px-3 text-xs rounded-3xl">
            Alerts
          </p>
          <p className="text-black text-base mt-4">
            Top Takeaways From Rebound: Helping Marketers Bounce Back
          </p>
          <p className="text-[#F04D24] mt-10">
            Team <strong>Sidhgiri</strong> - July 26, 2023
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section5;
