import React from "react";
import leftImage from "../images/usa-coal.png";
import rightImage from "../images/indonesian-coal.png";

const Section2 = () => {
  return (
    <section
      id="about-us"
      className="bg-gray-100 flex flex-wrap py-10 md:py-32 px-4 md:px-10"
    >
      {/* Left Side */}
      <div className="w-full md:w-1/2 md:px-36">
        <div className="text-xl md:text-2xl font-semibold mb-4 text-[#1C1D1E]">
          <span>Sidhgiri Coal:</span>
          <br />
          <span>We bring the right people together</span>
        </div>
        <p className="text-base md:text-lg mt-6 mb-6 text-gray-700 font-extralight">
          We bring the right people together Established in 1998, Sidhgiri
          Holdings is a renowned name in Coal Trading and Coal Supply industry.
          Its phenomenal growth, since its inception has made it a trusted brand
          among clients located in the northern, central and eastern states of
          India. Initially serving Brick Kiln Industry, today the company caters
          to the demand of over half a dozen different types of Industries and
          trades both in Steam/ Thermal Coal and Pet Coke, making it a
          multi-billion business operation.
        </p>
        <div className="text-center md:text-left">
          <button className="bg-[#F04D24] text-white py-3 md:py-4 px-4 md:px-6 rounded-3xl text-base md:text-sm font-regular mb-4 md:mb-0 mx-auto md:mx-0">
            About Us
          </button>
        </div>
      </div>

      {/* Right Side */}
      <div className="w-full md:w-1/2 flex justify-center items-center">
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 relative">
          <div className="max-w-[300px] relative md:top-20">
            <img src={leftImage} alt="Left Image" />
            <p className="text-center text-base md:text-sm mt-1 text-gray-700 font-extralight">
              USA Coal
            </p>
          </div>
          <div className="max-w-[300px] relative md:bottom-[50px]">
            <img src={rightImage} alt="Right Image" />
            <p className="text-center text-base md:text-sm mt-1 text-gray-700 font-extralight">
              Indonesian Coal
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section2;
