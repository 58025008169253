import React from "react";
import phoneIcon from "../images/phone.svg";
import mailIcon from "../images/mail.svg";
import locationIcon from "../images/location.svg";
import bigLogo from "../images/big-logo.svg";
import twitter from "../images/twitter-orange.svg";
import instagram from "../images/instagram-orange.svg";
import discord from "../images/discord-orange.svg";

const Section6 = () => {
  return (
    <section id="contact-us" className="bg-gray-50 py-8 md:py-24">
      <div className="bg-white max-w-6xl mx-auto md:flex">
        {/* Left Part - Contact Information */}
        <div className="bg-[#1A1A1A] rounded-lg mx-2 md:w-[450px] md:h-[610px] text-white">
          <div className="p-8">
            <div className="text-xl md:text-2xl font-semibold mb-4">
              Contact Information
            </div>
            <div className="text-white/60 mb-6 md:text-lg">
              Any questions or remarks? <br />
              Just write us a message!
            </div>
            <div className="flex items-center mb-6 md:mb-10 text-gray-300">
              <img src={phoneIcon} alt="Phone" className="w-6 h-6 mr-4" />
              <p>+91 9415201819, 7080902580</p>
            </div>
            <div className="flex items-center mb-6 md:mb-10 text-gray-300">
              <img src={mailIcon} alt="Mail" className="w-6 h-6 mr-4" />
              <p>abhishek@sidhgiri.com, sales@sidhgiri.com</p>
            </div>
            <div className="flex items-center text-gray-300">
              <img src={locationIcon} alt="Location" className="w-6 h-6 mr-4" />
              <p className="md:w-[300px]">
                BP - 271, Ravinagar Mughalsarai, 232101 Dist: Chandauli, Uttar
                Pradesh.
              </p>
            </div>
          </div>
          <div className="flex mt-8 md:mt-12 items-center">
            <img
              src={twitter}
              alt="Twitter"
              className="w-8 h-8 ml-4 md:ml-8 cursor-pointer"
            />
            <img
              src={instagram}
              alt="Instagram"
              className="w-8 h-8 ml-4 md:ml-8 cursor-pointer"
            />
            <img
              src={discord}
              alt="Discord"
              className="w-8 h-8 ml-4 md:ml-8 cursor-pointer"
            />
            <img
              src={bigLogo}
              alt="Big Logo"
              className="ml-auto w-20 h-20 md:w-32 md:h-32"
            />
          </div>
        </div>

        {/* Right Part - User Inputs */}
        <div className="w-full md:w-[850px] p-6 md:p-6">
          {/* Input Fields */}
          <div className="mb-6">
            <div className="flex flex-wrap -mx-2">
              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block mb-2 text-gray-700 text-sm font-medium">
                  First Name
                </label>
                <input
                  type="text"
                  className="w-full p-2 border-b border-gray-500 focus:outline-none text-gray-500"
                />
              </div>
              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block mb-2 text-gray-700 text-sm font-medium">
                  Last Name
                </label>
                <input
                  type="text"
                  className="w-full p-2 border-b border-gray-500 focus:outline-none text-gray-500"
                />
              </div>
            </div>
          </div>

          <div className="mb-6">
            <div className="flex flex-wrap -mx-2">
              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block mb-2 text-gray-700 text-sm font-medium">
                  Email
                </label>
                <input
                  type="email"
                  className="w-full p-2 border-b border-gray-500 focus:outline-none text-gray-500"
                />
              </div>
              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block mb-2 text-gray-700 text-sm font-medium">
                  Phone Number
                </label>
                <input
                  type="tel"
                  className="w-full p-2 border-b border-gray-500 focus:outline-none text-gray-500"
                />
              </div>
            </div>
          </div>

          {/* Select Subject */}
          <div className="mb-6 md:mt-8">
            <div className="text-gray-700 text-sm font-semibold mb-4">
              Select Subject?
            </div>
            <div className="flex flex-wrap items-center space-x-4">
              <input
                type="radio"
                id="general"
                name="subject"
                className="mr-1 appearance-none bg-white border border-gray-300 rounded-full w-4 h-4 focus:ring-1 focus:ring-[#F04D24] focus:bg-[#F04D24]"
              />
              <label
                htmlFor="general"
                className="text-black text-xs font-normal cursor-pointer"
              >
                General Inquiry
              </label>
              <input
                type="radio"
                id="other"
                name="subject"
                className="ml-4 mr-1 appearance-none bg-white border border-gray-300 rounded-full w-4 h-4 focus:ring-1 focus:ring-[#F04D24] focus:bg-[#F04D24]"
              />
              <label
                htmlFor="other"
                className="text-gray-700 text-xs font-normal cursor-pointer"
              >
                Other Inquiry
              </label>
            </div>
          </div>

          {/* Message */}
          <div className="mb-28 md:mt-14">
            <label className="block mb-2 text-gray-700 text-sm font-medium">
              Message
            </label>
            <textarea
              placeholder="Write your message..."
              className="w-full p-2 border-b border-gray-500 focus:outline-none text-gray-500"
              rows="1"
            />
          </div>

          {/* Send Button */}
          <div className="flex">
            <button className="bg-[#F04D24] text-white py-3 px-10 rounded-full text-sm font-semibold ml-auto">
              Send Message
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section6;
