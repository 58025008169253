import React from "react";

const Section4 = () => {
  return (
    <div className="bg-[#1A1A1A] py-16 md:py-48 text-white text-center">
      {/* Flex Items */}
      <div className="flex flex-col items-center md:flex-row justify-center space-y-6 md:space-y-0 md:space-x-10 text-left px-4 md:px-48">
        <div className="w-full md:w-[400px] md:mr-48">
          <p className="text-xl md:text-4xl font-semibold mb-2 md:mb-2">
            Global network of trusted partners
          </p>
        </div>
        <div className="w-full md:w-[650px]">
          <p className="text-base md:text-lg mb-4 md:mb-2 font-light">
            We have a committed group of experienced Salesman positioned at
            pretty much every niche and Corner of zone of activity in Gujarat,
            Punjab, Haryana, Himachal Pradesh, Uttar Pradesh, Uttrakhand, Bihar,
            Jammu & Kashmir, Rajasthan and the Union Territory of Chandigarh.
            Presently we are extending our deals into Southern and South Eastern
            India too, so we more open to our customers.
          </p>
        </div>
      </div>

      {/* White Lines */}
      <div className="flex justify-center mt-12 md:mt-32 px-4 md:px-48">
        <hr className="w-1/3 w-[33.33%] md:w-[33.33%] h-[1px] bg-white mx-2 md:mx-6" />
        <hr className="w-1/3 w-[33.33%] md:w-[33.33%] h-[1px] bg-white mx-2 md:mx-6" />
        <hr className="w-1/3 w-[33.33%] md:w-[33.33%] h-[1px] bg-white mx-2 md:mx-6" />
      </div>

      {/* Flex Items with Numbers */}
      <div className="flex justify-center mt-8 md:mt-16 space-x-4 md:space-x-8 text-left px-4 md:px-52">
        <div className="w-1/3 flex flex-col text-center md:text-left">
          <p className="text-3xl md:text-6xl font-semibold mb-2">1200+</p>
          <p className="text-xl md:text-2xl text-[#F04D24]">Clients</p>
        </div>
        <div className="w-1/3 flex flex-col text-center md:text-left">
          <p className="text-3xl md:text-6xl font-semibold mb-2">3</p>
          <p className="text-xl md:text-2xl text-[#F04D24]">Offices</p>
        </div>
        <div className="w-1/3 flex flex-col text-center md:text-left">
          <p className="text-3xl md:text-6xl font-semibold mb-2">300+</p>
          <p className="text-xl md:text-2xl text-[#F04D24]">Trades</p>
        </div>
      </div>
    </div>
  );
};

export default Section4;
